import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import vSelect from 'vue-select'
import { config } from '@/api/config-setting'
import { lockerSetting } from '@/api/locker-setting'
import LockerGroupDetail from './modal/LockerGroupDetail.vue'
import LockerDetail from './modal/LockerDetail.vue'
import ConfirmDialog from '@core/components/confirm-dialog/ConfirmDialog.vue'
import DeleteDialog from '@core/components/delete-dialog/DeleteDialog.vue'
import Treeselect from '@riophae/vue-treeselect'
import SideBarUpdateLockerStatus from './modal/SideBarUpdateLockerStatus.vue'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import PlanMaintenance from './modal/PlanMaintenance.vue'
import { data } from 'vue-echarts'
import ExcelJS from "exceljs";
import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver-es'
import readXlsxFile from 'read-excel-file';

export default {
  name: 'LockerSetting',
  components: {
    LockerGroupDetail,
    LockerDetail,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    'confirm-dialog': ConfirmDialog,
    'delete-dialog': DeleteDialog,
    Treeselect,
    SideBarUpdateLockerStatus,
    PlanMaintenance
  },
  data() {
    return {
      isLoading: false,
      ListBarLocaltion: [],
      listLockerArea: null,
      LockerAreaSelect: null,
      listLocker: [],
      dataPropsModalLockerArea: {
        type: null,
        data: null
      },
      listLockerAreaProps: [],
      LockerAreaSelectAddLocker: null,
      dataPropsModalLocker: {
        type: null,
        LockerArea: null,
        data: null
      },
      Search: {
        Date: this.getUTC('start', 'day'),
        LockerAreaId: null,
        LockerCode: null,
        ZoneId: [],
      },
      listLockerTree: [],
      listStaticLocker: [],
      listColor: [
        {
          Color: '#1FBC9C'
        },
        {
          Color: '#1CA085'
        },
        {
          Color: '#2ECC70'
        },
        {
          Color: '#27AF60'
        },
        {
          Color: '#3398DB'
        },
        {
          Color: '#2980B9'
        },
        {
          Color: '#A463BF'
        },
        {
          Color: '#8E43AD'
        },
        {
          Color: '#3D556E'
        },
        {
          Color: '#222F3D'
        },
        {
          Color: '#F2C511'
        },
        {
          Color: '#F39C19'
        },
        {
          Color: '#E84B3C'
        },
        {
          Color: '#C0382B'
        },
        {
          Color: '#DDE6E8'
        },
        {
          Color: '#BDC3C8'
        }
      ]
    }
  },
  created() {
    this.getListBarLocaltion()
    this.getListLockerGroup_RLKA01()
  },
  methods: {
    async getListLockerGroup_RLKA01() {
      this.isLoading = true
      const body = {
        ...this.Search
      }
      
      await lockerSetting.getListLockerGroup(body).then(res => {
        if(res.Status == '200') {
          this.listLockerArea = res.Data.LockerArea
          if(this.listLockerArea.length > 0) {
            if(this.LockerAreaSelect) {
              if(this.listLockerArea.find(x => x.Id == this.LockerAreaSelect)) {
                this.detailLockerArea(this.listLockerArea.find(x => x.Id == this.LockerAreaSelect))
              } else {
                this.LockerAreaSelect = this.listLockerArea[0].Id
                this.detailLockerArea(this.listLockerArea[0])
              }
            } else {
              this.LockerAreaSelect = this.listLockerArea[0].Id
              this.detailLockerArea(this.listLockerArea[0])
            }
          } else {
            this.LockerAreaSelect = null
            this.listLocker = []
          }
          
          
        }
      })
      await lockerSetting.getListLockerGroup({
        Date: this.Search.Date,
        LockerAreaId: null,
        LockerCode: null,
        ZoneId: [],
      }).then(res => {
        if(res.Status == 200) {
          this.listLockerAreaProps = []
          this.listLockerTree = JSON.parse(JSON.stringify(res.Data.LockerArea)).map(x => ({
            id: `Area_${x.Id}`,
            label: x.LockerAreaName,
            children: x.Locker,
          }))
          this.listLockerTree.forEach(x => {
            x.children.forEach(y => {
              y.id = y.Id
              y.label = y.LockerName
            })
          })
          res.Data.LockerArea.forEach(x => {
            this.listLockerAreaProps.push({
              Id: x.Id,
              LockerAreaName: x.LockerAreaName,
              LockerAreaCode: x.LockerAreaCode,
              ZoneId: x.ZoneId
            })
          })
        }
        this.isLoading = false
      })
    },
    openAddLockerArea() {
      this.dataPropsModalLockerArea.type = 'ADD'
      this.dataPropsModalLockerArea.data = {
        LockerAreaCode: null,
        LockerAreaName: null,
        ZoneId: null
      }
      this.$root.$emit('bv::show::modal', 'modal-locker-area')
    },
    openAddLocker(data) {
      this.dataPropsModalLocker.type = 'ADD'
      this.dataPropsModalLocker.LockerArea = data
      this.dataPropsModalLocker.data = null
      this.$root.$emit('bv::show::modal', 'modal-locker-detail')
    },
    async getListBarLocaltion() {
      await config.API_RBAAR().then(res => {
        this.ListBarLocaltion = res.Data.Bar
      })
    },
    handEvent(data) {
      if(data.type == 'after-create-locker-area') {
        this.getListLockerGroup_RLKA01()
      }
      if(data.type == 'after-create-locker') {
        this.getListLockerGroup_RLKA01()
      }
      if(data.type == 'after-update-status-locker') {
        this.getListLockerGroup_RLKA01()
      }
    },
    async deleteLockerArea(data) {
      this.isLoading = true
      const body = {
        LockerAreaId: [data.Id]
      }
      await lockerSetting.deleteLockerGroup(body).then(res => {
        this.showResToast(res)
        if(res.Status == '200') {
          if(this.LockerAreaSelect == data.Id) {
            this.LockerAreaSelect = null
          }
          this.getListLockerGroup_RLKA01()
        } else {
          this.isLoading = false
        }
      })
    },
    async deleteLocker(data) {
      this.isLoading = true
      const body = {
        LockerId: [data.Id]
      }
      await lockerSetting.deleteLocker_DLK01(body).then(res => {
        this.showResToast(res)
        if(res.Status == '200') {
          this.getListLockerGroup_RLKA01()
        } else {
          this.isLoading = false
        }
      })
    },
    detailLockerArea(data) {
      this.LockerAreaSelect = data.Id
      this.listLocker = data.Locker
      this.listStaticLocker = data.LockerStatistics
    },
    viewDetailLockerDetail(data) {
      this.dataPropsModalLockerArea.type = 'DETAIL'
      this.dataPropsModalLockerArea.data = data
      this.$root.$emit('bv::show::modal', 'modal-locker-area')
    },
    viewDetailLocker(item) {
      this.dataPropsModalLocker.type = 'DETAIL'
      this.dataPropsModalLocker.LockerArea = this.LockerAreaSelect
      this.dataPropsModalLocker.data = item
      this.$root.$emit('bv::show::modal', 'modal-locker-detail')
    },
    openSidebarUpdateStatus() {
      this.$root.$emit('bv::toggle::collapse', 'sidebar-update-status-locker')
    },
    openModalPlanMaintenance() {
      this.$root.$emit('bv::show::modal', 'modal-plan-maintenance')
    },
    async updateStatusLocker_ULK02(data, lk) {
      const body = {
        FromDate: this.getUTC('start', 'day'),
        ToDate: this.getUTC('start', 'day'),
        LockerId: [lk],
        Note: null,
        Status: data
    }
    await lockerSetting.UpdateStatus_ULK02(body).then(res => {
        this.showResToast(res)
        if(res.Status == 200) {
          this.getListLockerGroup_RLKA01()
        }
    })
  },
    async updateStatusLockerArea(data){
      let body = {
        LockerAreaId: [data.Id]
      }
      if(data.InActive) {
        await lockerSetting.ActiveLockerArea(body).then(res => {
          this.showResToast(res)
          if(res.Status == 200) {
            this.getListLockerGroup_RLKA01()
          }
        })
      } else {
        await lockerSetting.InactiveLockerArea(body).then(res => {
          this.showResToast(res)
          if(res.Status == 200) {
            this.getListLockerGroup_RLKA01()
          }
        })
      }
    },
    generateXLSX() {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("List Locker");
      const worksheetColor = workbook.addWorksheet("Color Locker");
      worksheet.columns = [
          { header: "Mã tủ Locker", key: "LockerCode"},
          { header: "Tên tủ locker", key: "LockerName"},
          { header: "Màu tủ locker", key: "Color"},
          { header: "Ghi chú locker", key: "Note"},
      ];
      worksheetColor.columns = [
        { header: "Mã tủ Locker", key: "Color"}
      ];
      this.listColor.forEach(x => {
        worksheetColor.addRow(x)
      })
      workbook.xlsx.writeBuffer().then(buffer => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `TemplateLockerExport.xlsx`)
      })
    },
    triggerClickChooFiles() {
      const fromChooseFile = document.querySelector('.form-import-excel__field-choosefile');
      fromChooseFile.click();
    },
    async readFileExcel() {
      await readXlsxFile(this.$refs.choosefileExcel.files[0]).then(async (rows) => {
        let data = []
        let check = true
        let checkColor = []
          rows.forEach((item,index) => {
            if(index != 0) {
              if(!this.listColor.find(x => x.Color == item[2])) {
                checkColor.push(item[2])
              }
              if(!item[0] || !item[1] || !item[2]) {
                check = false
              } else {
                data.push({
                  LockerAreaId: this.LockerAreaSelect,
                  LockerCode: String(item[0]),
                  LockerName: String(item[1]),
                  Color: item[2] ? String(item[2]) : null,
                  Note: item[3] ? String(item[3]) : null
                })
              }
            }
          });
          console.log(checkColor)
          if(checkColor.length > 0) {
            this.showToast('error', this.$t('golf_locker_please_change_color'))
          } else if(data.length > 0 && check) {
            await lockerSetting.createLocker_ILK01({
              Locker: data
            }).then(res => {
              this.showResToast(res)
                if(res.Status == '200') {
                  this.getListLockerGroup_RLKA01()
                }
            })
          } else {
            this.showToast('error', this.$t('golf_locker_please_input_excel'))
          }
          
      });
      const fromChooseFile = document.querySelector('.form-import-excel__field-choosefile');
      fromChooseFile.value = ""
    },
  },
}
