<template>
    <div>
        <b-modal
            id="modal-plan-maintenance"
            size="xl"
            centered
            hide-header
            :no-close-on-backdrop="true"
            v-model="isOpen"
        >
        <b-card-header class="banned-background-color-primary">
          <div class="d-flex justify-content-between align-items-center block-header">
            <h4 class="mb-0 text-primary">
              {{ $t('golf_locker_plan_title') }}
            </h4>
           
            <ez-icon
              @click="hideModal"
              icon="ezGolf-icon-x"
              class="cursor-pointer"
              size="24"
              color="#114A9F"
            />
          </div>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col cols="12">
                <b-card
                border-variant="0"
                >
                <b-form @submit.prevent>
                    <b-row>
                    
                    <b-col class="mb-1"
                        md="2">
                        <b-form-group :label="$t('golf_locker_plan_search_start_date')">
                            <date-range-picker
                              v-model="search.FromDate"
                                ref="picker"
                                :single-date-picker="true"
                                :ranges="false"
                                opens="right"
                                :time-picker="true"
                                :time-picker-increment="1"
                                :show-dropdowns="true"
                                :auto-apply="true"
                                :locale-data="{
                                    format: 'mm/dd/YYYY',
                                    daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                                    monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                                    firstDay: 0
                                }"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col class="mb-1"
                        md="2">
                        <b-form-group :label="$t('golf_locker_plan_search_end_date')">
                            <date-range-picker
                                 v-model="search.ToDate"
                                ref="picker"
                                :single-date-picker="true"
                                :ranges="false"
                                opens="right"
                                :time-picker="true"
                                :time-picker-increment="1"
                                :show-dropdowns="true"
                                :auto-apply="true"
                                :locale-data="{
                                    format: 'mm/dd/YYYY',
                                    daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                                    monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                                    firstDay: 0
                                }"
                            />
                        </b-form-group>
                    </b-col>
                   
                  
                    
                    <b-col align="right" style="padding-top: 25px;">
                        <b-button
                        variant="primary"
                        type="submit"
                        @click="getListPlanMaintenance()"
                        >
                        {{ $t('golf_common_search') }}
                        </b-button>
                    </b-col>
                    </b-row>
                </b-form>
                </b-card>
            </b-col>
            </b-row>
          <b-overlay
            :show="isLoading"
            :opacity="0.3"
            rounded="sm"
          >
            <div>
              <DxDataGrid
                ref="tablePackage"
                :height="400"
                class="table-plan"
                :show-borders="false"
                :data-source="listPlan"
                :show-column-lines="true"
                :show-row-lines="true"
                :allow-column-reordering="true"
                :allow-column-resizing="true"
                column-resizing-mode="widget"
                @cellClick="viewDetail"
              >
                <DxColumn
                  v-for="(item, i) in configTable"
                  v-if="(item.field != 'action') ? item.isShow : true"
                  :key="i.field"
                  :caption="item.caption"
                  :data-field="item.field"
                  :data-type="item.type"
                  :alignment="item.alignment"
                  :cell-template="(item.cellTemplate) ? item.cellTemplate : false"
                  :width="item.width"
                >
                  <DxColumn
                    v-for="(band, j) in item.bands"
                    v-if="band.isShow && item.bands.length > 0"
                    :key="band.field"
                    :caption="band.caption"
                    :data-field="band.field"
                    :data-type="band.type"
                    :group-cell-template="customText"
                    :cell-template="(band.cellTemplate) ? band.cellTemplate : false"
                    :alignment="band.alignment"
                    :width="item.width"
                  />
                </DxColumn>

                <template #from-date="{ data }">
                  <div v-if="data.data.FromDate">
                    {{ convertUTC(data.data.FromDate,'DD/MM/YYYY HH:mm') }}
                  </div>
                </template>

                <template #to-date="{ data }">
                  <div v-if="data.data.ToDate">
                    {{ convertUTC(data.data.ToDate,'DD/MM/YYYY HH:mm') }}
                  </div>
                </template>
                <template #action="{ data }">
                  <b-nav>
                    <b-nav-item-dropdown
                      id="dot-more-action"
                      right
                    >
                      <template slot="button-content">
                        <feather-icon
                          icon="MoreVerticalIcon"
                          color="#114A9F"
                        />
                      </template>
                      <!-- <b-dropdown-item>
                        <div
                          type="button"
                        >
                          {{ $t("golf_common_view_detail" ) }}
                        </div>
                      </b-dropdown-item> -->
                      <b-dropdown-item>
                        <div
                          style="color: #EA5455"
                          type="button"
                          @click="deletePlan(data.data)"
                        >
                          {{ $t('golf_locker_cancel_plan_maintance') }}
                        </div>
                      </b-dropdown-item>
                    </b-nav-item-dropdown>
                  </b-nav>
                </template>
                <template #action-button="{ data }">
                  <div class="d-flex justify-content-center">
                    <b-button
                      v-if="data.data.MaintenanceStatus == null"
                      variant="primary"
                      type="submit"
                      class="mr-1"
                      @click="UpdateMaintanceLockerStatus(data.data, 'START')"
                      >
                      {{ $t('golf_locker_start_plan_maintance') }}
                      </b-button>
                      <b-button
                      v-if="data.data.MaintenanceStatus == 'START'"
                      variant="primary"
                      type="submit"
                      @click="UpdateMaintanceLockerStatus(data.data, 'FINISH')"
                      >
                      {{ $t('golf_locker_finish_plan_maintance') }}
                      </b-button>
                      <b-button
                          v-if="data.data.MaintenanceStatus == 'FINISH'"
                          variant="outline-success"
                      >
                          <span class="align-middle d-flex align-items-center">
                          &nbsp; {{ $t('golf_locker_completed_plan_maintance') }}
                          </span>
                      </b-button>
                  </div>
                </template>
                <!-- Bật cố định column -->
                <DxColumnFixing :enabled="true" />

                <!-- Cho phép group -->
                <DxGroupPanel :visible="false" />

                <!-- Phân trang -->
                <DxPaging :page-size="perPage" />
                <DxPager
                  :visible="true"
                  display-mode="full"
                  :show-info="true"
                />
              </DxDataGrid>
            </div>
          </b-overlay>
        </b-card-body>
        <template #modal-footer>
            <div class="w-100">
                <b-button
                size="md"
                variant="none"
                class="float-right mr-1"
                @click="hideModal"
                >
                    {{ $t('golf_common_back') }}
                </b-button>
            
            </div>
        </template>
        </b-modal>
       <modalUpdateLockerPlan :data="dataLockerSelect" @event="handEvent" />
    </div>
</template>
<script>
import { lockerSetting } from '@/api/locker-setting'
import modalUpdateLockerPlan from './modalUpdateLockerPlan.vue'
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxHeaderFilter,
  DxGroupPanel,
  DxGrouping,
  DxScrolling,
  DxPaging,
  DxPager,
  DxSummary,
  DxLookup,
  DxTotalItem,
  DxGroupItem,
  DxMasterDetail,
  DxStringLengthRule,
  DxRequiredRule,
  DxRangeRule,
  DxValueFormat,
  DxColumnChooser,
  DxColumnFixing,
  DxSelection,
  DxExport,
} from 'devextreme-vue/data-grid'       
import 'devextreme/dist/css/dx.light.css'
import { data } from 'vue-echarts'
export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxFilterRow,
    DxHeaderFilter,
    DxGroupPanel,
    DxGrouping,
    DxScrolling,
    DxPaging,
    DxPager,
    DxSummary,
    DxLookup,
    DxTotalItem,
    DxGroupItem,
    DxMasterDetail,
    DxStringLengthRule,
    DxRequiredRule,
    DxRangeRule,
    DxValueFormat,
    DxColumnChooser,
    DxColumnFixing,
    DxSelection,
    DxExport,
    modalUpdateLockerPlan
    },
    data() {
      return {
        isOpen: false,
        currentPage: 1,
        perPage: 50,
        dataGridRef: 'tablePackage',
        optionPerPage: [10, 50, 100],
        configTable: [
          {
            caption: this.$t('golf_locker_table_locker_code'),
            field: 'LockerCode',
            isShow: true,
            alignment: 'center',
            width: '10%',
          },
          {
            caption: this.$t('golf_locker_table_locker_name'),
            field: 'LockerName',
            isShow: true,
            alignment: 'center',
            width: '12%',
          },
          {
            caption: this.$t('golf_locker_table_date_maintance_start'),
            field: 'FromDate',
            isShow: true,
            cellTemplate: 'from-date',
            alignment: 'center',
            width: '10%',
          },
          {
            caption: this.$t('golf_locker_table_date_maintance_end'),
            field: 'ToDate',
            isShow: true,
            cellTemplate: 'to-date',
            alignment: 'center',
            width: '10%',
          },
          {
            caption: this.$t('golf_locker_table_maintance_note'),
            field: 'Note',
            isShow: true,
            alignment: 'center',
            width: '15%',
          },
          {
            caption: this.$t('golf_locker_table_maintance_status'),
            field: 'Status',
            isShow: true,
            alignment: 'center',
            width: '10%',
          },
          {
            caption: this.$t('golf_locker_table_create_by_name'),
            field: 'CreateByName',
            isShow: true,
            alignment: 'center',
            width: '10%',
          },
          {
            caption: this.$t('golf_locker_table_update_by_name'),
            field: 'UpdateByName',
            isShow: true,
            alignment: 'center',
            width: '10%',
          },
          {
          caption: '',
          field: 'action-button',
          isShow: true,
          cellTemplate: 'action-button',
          alignment: 'center',
          width: '10%',
        },
          {
          caption: '',
          field: 'action',
          isShow: true,
          cellTemplate: 'action',
          alignment: 'center',
          width: '3%',
        },
        ],
        listPlan: [],
        search: {
          LockerId: [],
          FromDate: this.getUTC('start', 'day'),
          ToDate: this.getUTC('end', 'day')
        },
        isLoading: false,
        dataLockerSelect: null
      }
    }, 
    methods: {
      hideModal() {
            this.$bvModal.hide('modal-plan-maintenance')
        },
      async getListPlanMaintenance() {
        this.isLoading = true
        await lockerSetting.GetListPlanMaintance(this.search).then(res => {
          if(res.Status == 200) {
            this.listPlan = res.Data.LockerMaintenance
          }
          this.isLoading = false
        })
      },
      async deletePlan(data) {
        const body = {
          LockerPlan: [
            {
              Id: data.Id,
              IsDeleted: true,
              MaintenanceStatus: null,
              FromDate: data.FromDate,
              ToDate: data.ToDate,
            }
          ]
        }
        await lockerSetting.updatePlanLocker(body).then(res => {
          this.showResToast(res)
          if(res.Status == 200) {
            this.getListPlanMaintenance()
          }
        })
      },
      async UpdateMaintanceLockerStatus(data, Status) {
        const body = {
          LockerPlan: [
            {
              Id: data.Id,
              IsDeleted: false,
              MaintenanceStatus: Status,
              FromDate: data.FromDate,
              ToDate: data.ToDate,
            }
          ]
        }
        await lockerSetting.updatePlanLocker(body).then(res => {
          this.showResToast(res)
          if(res.Status == 200) {
            this.getListPlanMaintenance()
          }
        })
      },
      viewDetail(item, force) {
        if (item.column && item.column.name != 'action' && item.column.name != 'action-button' && item.rowType == 'data'  || force) {
          if(item.data.Id) {
            this.dataLockerSelect = item.data
            this.$bvModal.show('modal-edit-date')
          }
          
        }
      },
      handEvent(data) {
        if(data.type == 'after-update-locker') {
          this.getListPlanMaintenance()
        }
      },
    },
    created() {
      this.getListPlanMaintenance()
    },
    watch: {
      isOpen(value) {
        if(value) {
          this.getListPlanMaintenance()
        }
      }
    }
}
</script>
<style lang="scss">
      @import '@core/scss/vue/libs/vue-flatpicker.scss';
      @import '@core/scss/vue/libs/dx-grid.scss';
      .table-plan {
    .dx-datagrid-rowsview .dx-row > td, .dx-datagrid-rowsview .dx-row > tr > td {
        overflow: inherit;
    }
    .dx-datagrid-rowsview {
        .dx-datagrid-table {
            tr {
                td {
                    &:hover {
                        cursor: pointer;
                        &:last-child {
                            cursor: inherit;
                        }
                        &:first-child {
                            .dx-template-wrapper::before {
                                font-size: 13px;
                            }
                        }
                    }
                    &:first-child {
                        padding-left: 20px;
                        .dx-template-wrapper::before {
                            font-family: 'ezGolf' !important;
                            content: "\e958";
                            font-size: 0;
                            position: absolute;
                            left: 5px;
                            top: 9px;
                        }
                    }
                }
                &:hover {
                    td:first-child {
                        .dx-template-wrapper::before {
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }
}

#dot-more-action {
    .dropdown-toggle::after {
        background-image: none!important;
        display: none;
    }
    a.nav-link {
        padding: 0;
    }
    .delete {
        a {
            color: #EA5455;
        }
    }
}
</style>