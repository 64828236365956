<template>
    <div>
        <b-modal
            id="modal-locker-area"
            size="sm"
            v-model="isOpen"
            centered
            hide-header
            :no-close-on-backdrop="true"
        >
        <b-card-header class="banned-background-color-primary">
          <div class="d-flex justify-content-between align-items-center block-header">
            <h4 v-if="data.type == 'ADD'" class="mb-0 text-primary">
              {{ $t('golf_add_locker_area') }}
            </h4>
            <h4 v-if="data.type == 'DETAIL'" class="mb-0 text-primary">
              {{ $t('golf_update_locker_area') }}
            </h4>
            <ez-icon
              icon="ezGolf-icon-x"
              class="cursor-pointer"
              size="24"
              color="#114A9F"
              @click="hideModal"
            />
          </div>
        </b-card-header>
        <b-card-body>
            <validation-observer
                ref="detailLokerArea"
                tag="div"
            >
                <b-form
                    class="detail-caddy-group"
                    @submit.stop.prevent="handleSubmit"
                >
                    <b-form-group :label="$t('golf_locker_business_area')" class="label-requires">
                        <validation-provider
                            #default="{ errors }"
                            :name="$t('golf_locker_business_area')"
                            rules="required"
                            >
                                <v-select
                                    v-model="LockerAreaDetail.ZoneId"
                                    label="Name"
                                    :class="(errors.length > 0) ? 'v-select-error' : ''"
                                    :state="errors.length > 0 ? false : null"
                                    :options="ListBarLocaltion"
                                    :reduce="item => item.Id"
                                    :clearable="false"
                                    :placeholder="$t('golf_locker_business_area')"
                                />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group :label="$t('golf_locker_area_code')" class="label-requires">
                        <validation-provider
                            #default="{ errors }"
                            :name="$t('golf_locker_area_code')"
                            rules="required|max:50"
                            >
                            <b-form-input
                                v-model="LockerAreaDetail.LockerAreaCode"
                                :placeholder="$t('golf_locker_input_area_code')"
                                :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group :label="$t('golf_locker_area_name')" class="label-requires">
                        <validation-provider
                            #default="{ errors }"
                            :name="$t('golf_locker_area_name')"
                            rules="required|max:50"
                            >
                            <b-form-input
                                v-model="LockerAreaDetail.LockerAreaName"
                                :placeholder="$t('golf_input_locker_area_name')"
                                :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-form>
            </validation-observer>
        </b-card-body>
        <template #modal-footer>
            <div class="w-100">
                <b-button
                    v-if="data.type == 'ADD'"
                    variant="primary"
                    size="md"
                    class="float-right"
                    @click="createLockerArea()"
                >
                    {{ $t('golf_common_save') }}
                </b-button>
                <b-button
                    v-if="data.type == 'DETAIL'"
                    variant="primary"
                    size="md"
                    class="float-right"
                    @click="updateLockerArea()"
                >
                    {{ $t('golf_common_confirm') }}
                </b-button>
                <b-button
                size="md"
                variant="none"
                class="float-right mr-1"
                @click="hideModal"
                >
                    {{ $t('golf_common_back') }}
                </b-button>
            
            </div>
        </template>
        </b-modal>
    </div>
</template>
<script>
import { lockerSetting } from '@/api/locker-setting'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  required, min, max, alpha_num,
} from '@validations'
import { data } from 'vue-echarts'
export default {
    props: [
        'ListBarLocaltion',
        'data'
    ],
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            isOpen: false,
            LockerAreaDetail: {
                LockerAreaCode: null,
                LockerAreaName: null,
                ZoneId: null
            }
        }
    },
    watch: {
        isOpen(value) {
            if(value) {
                this.LockerAreaDetail = {
                    LockerAreaCode: this.data.data.LockerAreaCode,
                    LockerAreaName: this.data.data.LockerAreaName,
                    ZoneId: this.data.data.ZoneId
                }
            }
        }
    },
    methods: {
        hideModal() {
            this.$bvModal.hide('modal-locker-area')
        },
        async createLockerArea() {
            this.$refs.detailLokerArea.validate().then(async success => {
                if(success) {
                    const body = {
                        LockerArea: {
                            LockerAreaCode: this.LockerAreaDetail.LockerAreaCode,
                            LockerAreaName: this.LockerAreaDetail.LockerAreaName,
                            ZoneId: this.LockerAreaDetail.ZoneId
                        }
                    }
                    await lockerSetting.createLockerGroup(body).then(res => {
                        this.showResToast(res)
                        if(res.Status == '200') {
                            this.$emit('event', { type: 'after-create-locker-area' })
                            this.hideModal()
                            this.refreshData()
                        }
                    })
                }
            })
        },
        async updateLockerArea() {
            this.$refs.detailLokerArea.validate().then(async success => {
                if(success) {
                    const body = {
                        LockerArea: {
                            Id: this.data.data.Id,
                            LockerAreaCode: this.LockerAreaDetail.LockerAreaCode,
                            LockerAreaName: this.LockerAreaDetail.LockerAreaName,
                            ZoneId: this.LockerAreaDetail.ZoneId
                        }
                    }
                    await lockerSetting.updateLockerGroup(body).then(res => {
                        this.showResToast(res)
                        if(res.Status == '200') {
                            this.$emit('event', { type: 'after-create-locker-area' })
                            this.hideModal()
                            this.refreshData()
                        }
                    })
                }
            })
        },
        refreshData() {
            this.LockerAreaDetail = {
                LockerAreaCode: null,
                LockerAreaName: null,
                ZoneId: null
            }
        }
    },
}
</script>
<style lang="scss">
    .banned-background-color-primary {
        background: rgba(17, 74, 159, 0.12);
    }
</style>