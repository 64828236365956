<template>
    <div>
        <b-modal
            id="modal-locker-detail"
            size="sm"
            v-model="isOpen"
            centered
            hide-header
            :no-close-on-backdrop="true"
        >
        <b-card-header class="banned-background-color-primary">
          <div class="d-flex justify-content-between align-items-center block-header">
            <h4 v-if="data.type == 'ADD'" class="mb-0 text-primary">
              {{ $t('golf_add_locker_detail') }}
            </h4>
            <h4 v-if="data.type == 'DETAIL'" class="mb-0 text-primary">
              {{ $t('golf_update_locker_detail') }}
            </h4>
            <ez-icon
              icon="ezGolf-icon-x"
              class="cursor-pointer"
              size="24"
              color="#114A9F"
              @click="hideModal"
            />
          </div>
        </b-card-header>
        <b-card-body>
            <validation-observer
                ref="addLocker"
                tag="div"
            >
                <b-form
                    class="detail-caddy-group"
                    @submit.stop.prevent="handleSubmit"
                >
                    <b-form-group :label="$t('golf_locker_detail_locker_area')" class="label-requires">
                        <validation-provider
                            #default="{ errors }"
                            :name="$t('golf_locker_detail_locker_area')"
                            rules="required"
                            >
                                <v-select
                                    :disabled="data.type == 'DETAIL' ? false : true"
                                    v-model="dataAddLocker.LockerAreaId"
                                    label="LockerAreaName"
                                    :class="(errors.length > 0) ? 'v-select-error' : ''"
                                    :state="errors.length > 0 ? false : null"
                                    :options="listLockerArea"
                                    :reduce="item => item.Id"
                                    :clearable="false"
                                    :placeholder="$t('golf_locker_detail_input_locker_area')"
                                />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group :label="$t('golf_locker_code')" class="label-requires">
                        <validation-provider
                            #default="{ errors }"
                            :name="$t('golf_locker_locker_code')"
                            rules="required|max:50"
                            >
                            <b-form-input
                                v-model="dataAddLocker.LockerCode"
                                :placeholder="$t('golf_locker_input_locker_code')"
                                :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group :label="$t('golf_locker_locker_name')" class="label-requires">
                        <validation-provider
                            #default="{ errors }"
                            :name="$t('golf_locker_name')"
                            rules="required|max:50"
                            >
                            <b-form-input
                                v-model="dataAddLocker.LockerName"
                                :placeholder="$t('golf_locker_input_locker_name')"
                                :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group
                        :label="$t('golf_locker_locker_color')"
                        class="label-requires"
                      >
                        <validation-provider
                            #default="{ errors }"
                            :name="$t('golf_locker_color')"
                            rules="required"
                            >
                            <v-swatches
                                :class="(errors.length > 0) ? 'vue-swatches-error' : ''"
                                v-model="dataAddLocker.Color"
                                shapes="circles"
                                :swatch-size="20"
                                :spacing-size="5"
                                :swatch-style="{ width: '20px', height: '20px' }"
                                :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group :label="$t('golf_locker_note')">
                        <validation-provider
                            #default="{ errors }"
                            :name="$t('golf_locker_note')"
                            rules="max:250"
                            >
                            <b-form-textarea
                                v-model="dataAddLocker.Note"
                                :placeholder="$t('golf_input_locker_note')"
                                :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-form>
            </validation-observer>
        </b-card-body>
        <template #modal-footer>
            <div class="w-100">
                <b-button
                    v-if="data.type == 'ADD'"
                    variant="primary"
                    size="md"
                    class="float-right"
                    @click="createLocker()"
                >
                    {{ $t('golf_common_confirm') }}
                </b-button>
                <b-button
                    v-if="data.type == 'DETAIL'"
                    variant="primary"
                    size="md"
                    class="float-right"
                    @click="updateLockerArea()"
                >
                    {{ $t('golf_common_confirm') }}
                </b-button>
                <b-button
                size="md"
                variant="none"
                class="float-right mr-1"
                @click="hideModal"
                >
                    {{ $t('golf_common_back') }}
                </b-button>
            
            </div>
        </template>
        </b-modal>
    </div>
</template>
<script>
import { lockerSetting } from '@/api/locker-setting'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  required, min, max, alpha_num,
} from '@validations'
import VSwatches from 'vue-swatches'
import 'vue-swatches/dist/vue-swatches.css'
export default {
    props: ['listLockerArea' , 'data'],
    components: {
        ValidationProvider,
        ValidationObserver,
        VSwatches
    },
    data() {
        return {
            isOpen: false,
            dataAddLocker: {
                LockerAreaId: null,
                LockerCode: null,
                LockerName: null,
                Color: null,
                Note: null
            }
        }
    },
    watch: {
        isOpen(value) {
            if(value) {
                if(this.data.type == 'DETAIL') {
                    this.dataAddLocker.LockerAreaId = this.data.data.LockerAreaId
                    this.dataAddLocker.LockerCode = this.data.data.LockerCode
                    this.dataAddLocker.LockerName = this.data.data.LockerName
                    this.dataAddLocker.Color = this.data.data.Color
                    this.dataAddLocker.Note = this.data.data.Note
                } else {
                    this.dataAddLocker.LockerAreaId = this.data.LockerArea
                }
            } else {
                this.refreshData()
            }
        }
    },
    methods: {
        hideModal() {
            this.$bvModal.hide('modal-locker-detail')
        },
        async createLocker() {
            this.$refs.addLocker.validate().then(async success => {
                if(success) {
                    await lockerSetting.createLocker_ILK01({
                        Locker: [this.dataAddLocker]
                    })
                    .then(res => {
                        this.showResToast(res)
                        if(res.Status == '200') {
                            this.hideModal()
                            this.$emit('event', { type: 'after-create-locker' })
                            this.refreshData()
                        }
                    })
                }
            })
        },
        async updateLockerArea() {
            this.$refs.addLocker.validate().then(async success => {
                if(success) {
                    await lockerSetting.updateLocker_ULK01({
                        Locker: {
                            ...{
                                Id: this.data.data.Id
                            },...this.dataAddLocker
                            
                        }
                    })
                    .then(res => {
                        this.showResToast(res)
                        if(res.Status == '200') {
                            this.hideModal()
                            this.$emit('event', { type: 'after-create-locker' })
                        }
                    })
                }
            })
        },
        refreshData() {
            this.dataAddLocker = {
                LockerAreaId: null,
                LockerCode: null,
                LockerName: null,
                Color: null,
                Note: null
            }
        }
    },
}
</script>
<style lang="scss">
    @import '@/assets/scss/variables/variables';
</style>