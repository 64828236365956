<template>
    <div>
        <b-modal
            id="modal-edit-date"
            size="sm"
            centered
            hide-header
            :no-close-on-backdrop="true"
            v-model="isOpen"
            >
            <b-card-header class="banned-background-color-primary">
            <div class="d-flex justify-content-between align-items-center block-header">

            <h4 class="mb-0 text-primary">
            {{ $t('golf_update_locker_plan_detail') }}
            </h4>
            <ez-icon
            icon="ezGolf-icon-x"
            class="cursor-pointer"
            size="24"
            color="#114A9F"
            @click="hideModal"
            />
            </div>
            </b-card-header>
            <b-card-body>
            <validation-observer
                ref="editDate"
                tag="div"
            >
                <b-form
                    class="detail-caddy-group"
                    @submit.stop.prevent="handleSubmit"
                >
                    <b-form-group :label="$t('golf_locker_plan_start_date')" class="label-requires">
                        <validation-provider
                            #default="{ errors }"
                            :name="$t('golf_locker_locker_code')"
                            rules="required"
                            >
                            <date-range-picker
                                :state="errors.length > 0 ? false : null"
                                v-model="updateLocker.FromDate"
                                ref="picker"
                                :single-date-picker="true"
                                :ranges="false"
                                opens="right"
                                :time-picker="true"
                                :time-picker-increment="1"
                                :show-dropdowns="true"
                                :auto-apply="true"
                                :locale-data="{
                                    format: 'mm/dd/YYYY',
                                    daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                                    monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                                    firstDay: 0
                                }"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group :label="$t('golf_locker_plan_end_date')" class="label-requires">
                        <validation-provider
                            #default="{ errors }"
                            :name="$t('golf_locker_name')"
                            rules="required"
                            >
                            <date-range-picker
                                :state="errors.length > 0 ? false : null"
                                v-model="updateLocker.ToDate"
                                ref="picker"
                                :single-date-picker="true"
                                :ranges="false"
                                opens="right"
                                :time-picker="true"
                                :time-picker-increment="1"
                                :show-dropdowns="true"
                                :auto-apply="true"
                                :locale-data="{
                                    format: 'mm/dd/YYYY',
                                    daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                                    monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                                    firstDay: 0
                                }"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-form>
            </validation-observer>
            </b-card-body>
            <template #modal-footer>
            <div class="w-100">
                <b-button
                    variant="primary"
                    size="md"
                    class="float-right"
                    @click="updatePlanLocker"
                >
                    {{ $t('golf_common_confirm') }}
                </b-button>
                <b-button
                size="md"
                variant="none"
                class="float-right mr-1"
                @click="hideModal"
                >
                    {{ $t('golf_common_back') }}
                </b-button>

            </div>
            </template>
            </b-modal>
    </div>
</template>
<script>
import { lockerSetting } from '@/api/locker-setting'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  required, min, max, alpha_num,
} from '@validations'
export default {
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    props: ['data'],
    data() {
        return {
            updateLocker: {
                FromDate: this.getUTC('start', 'day'),
                ToDate: this.getUTC('start', 'day')
            },
            isOpen: false
        }
    },
    watch: {
        isOpen(value) {
            if(value) {
                this.updateLocker.FromDate = this.data.FromDate
                this.updateLocker.ToDate = this.data.ToDate
            }
        }
    },
    methods: {
        hideModal() {
            this.$bvModal.hide('modal-edit-date')
        },
        async updatePlanLocker() {
            const body = {
                LockerPlan: [
                    {
                        Id: this.data.Id,
                        IsDeleted: false,
                        MaintenanceStatus: this.data.MaintenanceStatus,
                        FromDate: this.updateLocker.FromDate,
                        ToDate: this.updateLocker.ToDate,
                    }
                ]
            }
            await lockerSetting.updatePlanLocker(body).then(res => {
                this.showResToast(res)
                if(res.Status == 200) {
                    this.hideModal()
                    this.$emit('event', { type: 'after-update-locker' })
                }
            })
        }
    },
}
</script>
<style lang="">
    
</style>