<template>
    <b-sidebar
      id="sidebar-update-status-locker"
      bg-variant="white"
      right
      backdrop
      shadow
      no-header
      :no-close-on-backdrop="true"
      width="480px"
      v-model="isOpen"
    >
        <div class="header-sidebar p-1 d-flex justify-content-between align-items-center">
          <h4 class="mb-0 text-primary mr-auto">
            {{ $t('golf_update_locker_status_title') }}
          </h4>
          <div class="action">
            <b-button
              :disabled="disableButton"
              variant="primary"
              @click="updateStatus_ULK02"
            >
              {{ $t('golf_common_save') }}
            </b-button>
            <ez-icon
              v-b-tooltip.hover
              title="Esc"
              icon="ezGolf-icon-x"
              size="24"
              class="cursor-pointer ml-1"
              color="#114A9F"
              @click="hideSibar"
            />

          </div>
        </div>
        <b-card-body>
            <div
            style="padding: 5px;"
            >
            <b-form @submit.prevent>
                <b-row>
                <b-col md="12">
                <b-form-group :label="$t('golf_locker_select_status')">
                 
                  <v-select
                      v-model="dataUpdateStatus.Status"
                      label="Label"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="listStatus"
                      :clearable="false"
                      :reduce="item => item.Value"
                    >
                      <template #selected-option="{ Label , Color }">
                        <div
                          :style="{ background: Color}"
                          class="dot-size-10 d-inline-block rounded-circle"
                        />
                        {{ Label }}
                      </template>
                      <template v-slot:option="item">
                        <div
                          :style="{ background: item.Color}"
                          class="dot-size-10 d-inline-block rounded-circle"
                        />
                        {{ item.Label }}
                      </template>
                    </v-select>
                </b-form-group>
              </b-col>
                <b-col v-if="dataUpdateStatus.Status == 'MAINTENANCE'" md="6">
                    <b-form-group :label="$t('golf_locker_day_start_broken')">
                        <date-range-picker
                            v-model="dataUpdateStatus.FromDate"
                            ref="picker"
                            :single-date-picker="true"
                            :ranges="false"
                            opens="right"
                            :time-picker="true"
                            :time-picker-increment="1"
                            :show-dropdowns="true"
                            :auto-apply="true"
                            :locale-data="{
                                format: 'mm/dd/YYYY',
                                daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                                monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                                firstDay: 0
                            }"
                        />
                    </b-form-group>
                    
                </b-col>
                <b-col md="6" v-if="dataUpdateStatus.Status == 'MAINTENANCE'">
                    <b-form-group :label="$t('golf_locker_day_end_fix')">
                        <date-range-picker
                            v-model="dataUpdateStatus.ToDate"
                            ref="picker"
                            :single-date-picker="true"
                            :ranges="false"
                            opens="left"
                            :time-picker="true"
                            :time-picker-increment="1"
                            :show-dropdowns="true"
                            :auto-apply="true"
                            :locale-data="{
                                format: 'mm/dd/YYYY',
                                daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                                monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                                firstDay: 0
                            }"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="12">
                    <b-form-group :label="$t('golf_update_status_locker_note')">
                        <b-form-textarea v-model="dataUpdateStatus.Note" class="mb-0" rows="1"
                            max-rows="6"/>
                    </b-form-group>
                </b-col>
                </b-row>
                <b-row class="mt-1">
                <b-col
                    md="8"
                    class="pr-0"
                >
                    <treeselect
                    v-model="selectLocker"
                    :options="listLockerTree"
                    :default-expand-level="1"
                    :clearable="false"
                    class="d-tree-select"
                    :placeholder="$t('golf_locker_enter_code_locker')"
                    :multiple="true"
                    :limit-text="fnCount"
                    :limit="1"
                    :clear-on-select="false"
                    />
                </b-col>
                <b-col md="4">
                    <b-button
                    variant="primary"
                    style="padding: 9px"
                    class="w-100"
                    @click="addLockerByDetail()"
                    >
                    {{ $t('golf_locker_update_status_add_locker') }}
                    </b-button>
                </b-col>
                </b-row>
                <div class="scroll-caddy mt-2">
                    <div v-for="(item,index) in listLockerSelected" :key="index">
                        <h4>
                            {{ item.label }} ({{ item.children.length }})
                        </h4>
                        <div v-for="(lk,index1) in item.children" :key="index1" class="ml-1 d-flex justify-content-between mb-1">
                            <div style="font-size: 15px;">
                                <div
                                :style="{ background: lk.Color}"
                                class="dot-size-10 d-inline-block rounded-circle"
                                />
                                {{ lk.LockerName }}
                            </div>
                            <div
                                class="ml-auto"
                            >
                                <ez-icon
                                    icon="ezGolf-icon-trash"
                                    size="16"
                                    class="text-danger pointer"
                                    @click="removeLocker(index,index1)"
                                />
                            </div>
                        </div>
                    </div>
                <div
                    v-if="listLockerSelected.length === 0"
                    class="text-center mt-5"
                >
                    <img
                    src="@/assets/images/golf/no_caddy.png"
                    class="pb-1"
                    >
                    <p>{{ $t('golf_update_locker_txt_no_locker') }}</p>
                </div>
                </div>
            </b-form>
            </div>
        </b-card-body>
       
    </b-sidebar>
</template>
<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { isString } from '@vueuse/core'
import { lockerSetting } from '@/api/locker-setting'

export default {
    components: {
        Treeselect
    },
    props: [
      'listLockerTree'  
    ],
    data() {
        return {
            isOpen: false,
            disableButton: false,
            listStatus: [
                {
                    Label: this.$t('golf_locker_status_maintenance'),
                    Value: 'MAINTENANCE',
                    Color: 'rgba(253, 185, 19, 0.5)'
                },
                {
                    Label: this.$t('golf_locker_status_in_active'),
                    Value: 'IN_ACTIVE',
                    Color: 'rgba(156, 158, 164, 0.5)'
                }
            ],
            selectLocker: null,
            listLockerSelected: [],
            dataUpdateStatus: {
                FromDate: this.getUTC('start', 'day'),
                ToDate: this.getUTC('end', 'day'),
                LockerId: [],
                Note: null,
                Status: null
            }
        }
    },
    methods: {
        hideSibar() {
            this.$root.$emit('bv::toggle::collapse', 'sidebar-update-status-locker')
            this.clearData()
        },
        addLockerByDetail() {
            const listLocker = []
            const listIdArea = this.selectLocker.filter(x => isString(x))
            const listIdLocker = this.selectLocker.filter(x => !isString(x))
            JSON.parse(JSON.stringify(this.listLockerTree)).forEach(x => {
                if(listIdArea.includes(x.id)) {
                    listLocker.push(x)
                } else if(x.children.filter(x => listIdLocker.includes(x.id)).length > 0) {
                    listLocker.push({
                        id: x.id,
                        label: x.label,
                        children: x.children.filter(x => listIdLocker.includes(x.id))
                    })
                }
            });
            this.listLockerSelected = listLocker
            this.selectLocker = null
        },
        removeLocker(index1,index2) {
            this.listLockerSelected[index1].children.splice(index2, 1)
            if(this.listLockerSelected[index1].children.length == 0) {
                this.listLockerSelected.splice(index1, 1)
            }
        },
        async updateStatus_ULK02() {
            if(!this.dataUpdateStatus.Status) {
                this.showToast('error', this.$t('golf_locker_please_select_status'))
            } else if(this.listLockerSelected.length == 0) {
                this.showToast('error', this.$t('golf_locker_please_select_locker'))
            } else {
                this.disableButton = true
                let ListLockerId = []
                this.listLockerSelected.forEach(x => {
                    ListLockerId = [...ListLockerId , ...x.children.map(x => x.id)]
                })
                const body = {
                    FromDate: this.dataUpdateStatus.FromDate,
                    ToDate: this.dataUpdateStatus.ToDate,
                    LockerId: ListLockerId,
                    Note: this.dataUpdateStatus.Note,
                    Status: this.dataUpdateStatus.Status
                }
                await lockerSetting.UpdateStatus_ULK02(body).then(res => {
                    this.showResToast(res)
                    if(res.Status == 200) {
                        this.$emit('event', { type: 'after-update-status-locker' })
                        this.hideSibar()
                    }
                    this.disableButton = false
                })
            }
        },
        clearData() {
            this.dataUpdateStatus = {
                FromDate: this.getUTC('start', 'day'),
                ToDate: this.getUTC('end', 'day'),
                LockerId: [],
                Note: null,
                Status: null
            }
            this.listLockerSelected = []
        }
    }
}
</script>
<style lang="scss">
#sidebar-update-status-locker {
    .b-sidebar-body {
    overflow-y: visible !important;
   }
.scroll-caddy {
    height: calc(100vh - 290px);
    overflow: auto;
}
.scroll-caddy::-webkit-scrollbar {
    width: 4px;
    border: 1px solid #d5d5d5;
}

.scroll-caddy::-webkit-scrollbar-track {
    border-radius: 0;
    background: #eeeeee;
}

.scroll-caddy::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: #b0b0b0;
}
}

</style>